import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Navigation from './Navigation'
// import celonis from '../resources/images/celonis.svg'
import Footer from './Footer'
// CSS imports
import mainCSS from '../resources/CSS/main.css' // eslint-disable-line no-unused-vars
import navbarCSS from '../resources/CSS/navbar.css' // eslint-disable-line no-unused-vars
import Responsive from '../resources/CSS/responsive.css' // eslint-disable-line no-unused-vars
import NavigationResponsive from '../resources/CSS/navigation-responsive.css' // eslint-disable-line no-unused-vars

class Template extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: false,
        }
    }

    componentDidMount() {
        if (
            !(
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring-thank-you/' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring-thank-you' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter1' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter1/' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter2' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter2/' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter3' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/chapter3/' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/intro' ||
                window.location.pathname ===
                    '/guide/everything-need-know-about-kafka-monitoring/intro/'
            )
        ) {
            this.setState({
                ...this.state,
                banner: true,
            })
        }
    }

    render() {
        const {
            children,
            newsletter,
            onlyLogo,
            // hasBanner,
            linkColor,
            isTransparent,
            logo,
            type,
            noFooter,
        } = this.props
        let navigation
        // Remove Banner from all pages
        //GUILLAUME CHANGES
        //let hasBannerTemp = false
        let hasBannerTemp = true
        // Logic for return navbar
        if (typeof location !== 'undefined') {
            navigation = (
                <Navigation
                    linkColor={linkColor}
                    hasBanner={hasBannerTemp}
                    isTransparent={isTransparent}
                    logo={logo}
                    onlyLogo={onlyLogo}
                    type={type}
                />
            )
        }

        return (
            <Fragment>
                {/* {this.state.banner && (
          <section className="home-banner bg-dark">
            <span className="f-15">
              {' '}
              Lenses.io has joined forces with&nbsp;
              <img
                className="img-flud celonis-logo-nav mb-1"
                src={celonis}
                alt="Celonis"
              />
              <a
                href="/blog/2021/10/celonis-acquires-lensesio/"
                // target="_blank"
                // rel="noopener noreferrer"
                className="ml-1 text-white mobile-padding-right-half mobile-margin-reset"
              >
                | Learn more <i className="fa fa-chevron-right text-white"></i>
              </a>
            </span>
          </section>
        )} */}

                {/*Start of Guillaume*/}
                {this.state.banner && (
                    <section className='home-banner' style={{ background: '#5584ba' }}>
                        <span className='f-15'>
                            {' '}
                            Lenses 5.5 is out: Data movement with GitOps + new Open-source Kafka
                            connectors
                            <a
                                href='https://lenses.io/blog/2024/03/lenses-5.5-self-service-data-movement-kafka-gitops/'
                                rel='noreferrer'
                                target={'_blank'}
                                className='ml-1 text-white mobile-padding-right-half mobile-margin-reset'>
                                | Learn more <i className='fa fa-chevron-right text-white'></i>
                            </a>
                        </span>
                        {/*<span className='f-15'>*/}
                        {/*    {' '}*/}
                        {/*    Lenses 5.5 Webinar: Data movement with GitOps | 12 April - 4pm CET/ 10am*/}
                        {/*    ET*/}
                        {/*    <a*/}
                        {/*        href='https://celonis.zoom.us/webinar/register/WN_QVZoLN-rTsSb7JC-pucl0Q#/registration'*/}
                        {/*        rel='noreferrer'*/}
                        {/*        target={'_blank'}*/}
                        {/*        className='ml-1 text-white mobile-padding-right-half mobile-margin-reset'>*/}
                        {/*        | Register Now! <i className='fa fa-chevron-right text-white'></i>*/}
                        {/*    </a>*/}
                        {/*</span>*/}
                    </section>
                )}
                {/*End of Guillaume*/}

                {navigation}
                {children}
                <Footer newsletter={newsletter} noFooter={noFooter} />
            </Fragment>
        )
    }
}

export default Template

Template.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    newsletter: PropTypes.bool,
    onlyLogo: PropTypes.bool,
    hasBanner: PropTypes.bool,
    isTransparent: PropTypes.bool,
    noFooter: PropTypes.bool,
    linkColor: PropTypes.string,
    logo: PropTypes.string,
    type: PropTypes.string,
}
